/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 4, 2024 */



@font-face {
    font-family: 'montserratbold';
    src: url('montserrat-bold-webfont.woff2') format('woff2'),
         url('montserrat-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montserratregular';
    src: url('montserrat-regular-webfont.woff2') format('woff2'),
         url('montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}