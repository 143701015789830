body, html {
  font-family: 'montserratregular';
  font-size: 13px;
  line-height: 1.4em;
  margin: 0;
  padding: 0;
  height: 100%;
  background: #0D0D0D;
}

a {
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  body, html {
    font-size: 15px;
  }
}